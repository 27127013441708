/* Footer.css */

.footerText {
  position: fixed;
  bottom: 25px;
  width: 100%;
  text-align: center;
  color: #808080;
  font-family: Verdana, Arial, sans-serif;
  font-size: 1em;
  font-weight: 500;
}

.footerText a {
  color: #808080;
  text-decoration: none;
  font-family: Verdana, Arial, sans-serif;
  font-size: 1em;
  font-weight: 500;
}

.footerText a:hover {
  opacity: 0.75;
}
