body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: Verdana, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black !important;
  color:#808080 !important;
	font-size:1em !important;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
  font-family: Verdana, Arial, sans-serif !important;
  background-color: black;
  font-size:1em !important;
}

.form-control:hover {
  /* border-color: #81CA00 !important; */
  font-family: Verdana, Arial, sans-serif !important;
  font-size:1em !important;
}

.form-control:focus {
  /* border-color: #81CA00 !important; */
  box-shadow: 0 0 0 !important;
  font-family: Verdana, Arial, sans-serif !important;
  font-size:1em !important;
}

.titlebar {
  position:relative;
  font-family: Verdana, Arial, sans-serif !important;
  font-size:large;
  color:#dddddd;
  /* background-color:#222222; */
  min-height:65px;
  /* background-image: linear-gradient(to top, #111111 0%, #222222 15%, #222222 85%, black 100%); */
  /* background-image: -webkit-linear-gradient(to top, rgb(0,0,0) 0%, rgb(20,92,9) 10%, rgb(20,92,9) 90%, rgb(255,255,255) 100%); */
  margin-bottom:0px;
  width:100%;
  text-align:center;
}

.form {
  text-align: left !important;
  padding: 20px 30px;
}

.outer-container {
	max-width:80%;
	max-width:500px;
	text-align:center;
	margin-left:auto;
	margin-right:auto;
	position:relative;
	/* box-shadow: 0px 0px 80px #353131; */
	/* margin-bottom:40px; */
	/* border: 1px solid #1F1F1F;	/* need a border for monitors that don't display drop shadows or gray gradients  very well */ 
  
}

.page-container {
	/* height:90%; */
	/* min-height:500px;  */
	box-sizing:border-box;
	-moz-box-sizing:border-box;
	padding:20px;
  /* width: 450px; */
}

.bigButton {
	text-align:center;
/*	margin:40px;
	margin-top:0px; */
	/* margin-bottom:5%; */
	/* background-color:rgb(25, 25, 25) !important; */
  background-color:#212121 !important;
	vertical-align: middle !important;
  border-radius: 0 !important;
  color: #808080 !important;
  font-family: Verdana, Arial, sans-serif !important;
  font-size: 16px !important;
  outline-style: none !important;
  /* font-weight: bold !important; */
  width: 100% !important;
  
}

.input {
  border-radius: 0 !important;
  /* width: 95% !important; */
  box-sizing:border-box !important;
	vertical-align:top !important;
  background-color: #3c3939 !important;
  /* outline-style: none !important; */
  border-color: #3c3939 !important;
  color: white !important;
  font-family: Verdana, Arial, sans-serif !important;
  font-size: 16px !important;
  padding: 8px !important;
  border: 1px !important;
  width: 100%;
}

.input:focus {
	border:2px solid green !important;
}

.submit1 {
  /* border-radius: 0 !important; */
  /* background-color: #151515 !important; */
  color: #81CA00 !important;
  /* border-color: #151515 !important; */
  font-weight: 549 !important;
  font-family: Verdana, Arial, sans-serif !important;
  /* font-size: 16px !important; */
  /* box-sizing:border-box !important; */
  display:inline-block;
	min-height:4.5em;
	line-height:4.5em;
	vertical-align:middle;
	text-align:center;
	margin:0px !important;
	background-color: #1b1b1b !important;
	box-sizing:border-box !important;
	border:0.1px solid #232323 !important;
	border-radius:0px !important;
	margin-top:0px !important;
	/* -webkit-appearance:none !important;		don't use iOS default button styling */
	/* cursor:pointer !important; */
	padding:4px !important; 
  width: 100%;
  
}

.submit:hover {
  opacity: 0.6;
}

::placeholder {
  color: #808080 !important;
  font-family: Verdana, Arial, sans-serif !important;
  font-size: 14px !important;
}

.support {
  font-size: 14px !important;
  margin: 0 !important;
  width: 100% !important;
  font-family: Verdana, Arial, sans-serif !important;
}

.link {
  color: #81CA00 !important;
}

/* form {
  min-height:4em; 
	line-height:4em; 
  padding: 20px 30px;
  width: 410px;
}  */

.card1 {
  margin: 0;
  padding: 0;
}

.custom-select {
  /* appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="4" height="5"><path fill="currentColor" d="M2 0L0 2h4L2 0z"/></svg>') no-repeat right; */
  /* padding-right: 20px; adjust as needed */
  /* background-position-x: calc(100% - 10px); adjust as needed */
  /* background-color: #000; Example background color */
  /* color: white; */
  border-radius: 0 !important;
  /* width: 95% !important; */
  box-sizing:border-box !important;
	vertical-align:top !important;
  background-color: #3c3939 !important;
  /* outline-style: none !important; */
  border-color: #3c3939 !important;
  color: white !important;
  font-family: Verdana, Arial, sans-serif !important;
  font-size: 16px !important;
  padding: 8px !important;
  border: 1px !important;
  width: 100%;
}
.app-name{
  color: gray !important;
}